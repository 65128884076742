import config from "../../../config.json"

export default {
	namespaced: true,

	state: {
		tokens: [],
		pages: {
			current: null,
			limit: 10,
			offset: null,
			total: null
		}
	},

	actions: {

		initialize: ({ commit, state }) => {

			fetch(config.api.url + "/tokens?limit=" + state.pages.limit, {
				method: "GET",
				headers: { "Authorization": "Bearer " + localStorage.getItem("token") }
			}).then(Response => Response.json()).then(Response => {
				commit("set_tokens", Response.data.tokens)
				commit("set_pages", Response.data.pages)
			})

		},

		/**
		 * This action is responsible for fetching data from the
		 * upstream API and then update the vuex state according
		 * to the returned data.
		 */
		fetch: ({ commit, state }, params) => {

			fetch(config.api.url + "/tokens?limit=" + state.pages.limit + "&page=" + params.page, {
				method: "GET",
				headers: { "Authorization": "Bearer " + localStorage.getItem("token") }
			}).then(Response => Response.json()).then(Response => {
				commit("set_tokens", Response.data.tokens)
				commit("set_pages", Response.data.pages)
			})

		}

	},

	mutations: {

		set_tokens: (state, data) => { state.tokens = data },
		set_pages: (state, data) => { state.pages = data }

	}
}
