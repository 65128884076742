import Vue from 'vue'
import VueRouter from 'vue-router'
import isAuthenticated from '../middleware/auth'

Vue.use(VueRouter)

const Routes = [
	{
		path: "/",
		name: "Root",
		beforeEnter: isAuthenticated
	},
	{
		path: "/home",
		name: "Home",
		component: () => import(/* webpackChunkName: "login" */ "../views/Home"),
		beforeEnter: isAuthenticated
	},
	{
		path: "/login",
		name: "Login",
		component: () => import(/* webpackChunkName: "login" */ "../views/Login")
	},
	{
		path: "/register",
		name: "Register",
		component: () => import(/* webpackChunkName: "register" */ "../views/Register")
	},
	{
		path: "/logout",
		name: "Logout",
		component: () => import(/* webpackChunkName: "logout" */ "../views/Logout")
	},
	{
		path: "/campaigns",
		name: "Campaigns",
		component: () => import(/* webpackChunkName: "campaigns" */ "../views/Campaigns"),
		beforeEnter: isAuthenticated
	},
	{
		path: "/history",
		name: "History",
		component: () => import(/* webpackChunkName: "history" */ "../views/History"),
		beforeEnter: isAuthenticated
	},
	{
		path: "/contacts",
		name: "Contatcs",
		component: () => import(/* webpackChunkName: "contatcs" */ "../views/Contacts"),
		beforeEnter: isAuthenticated
	},
	{
		path: "/contacts/create",
		name: "CreateContacts",
		component: () => import(/* webpackChunkName: "create_contacts" */ "../views/Contacts/Create"),
		beforeEnter: isAuthenticated
	},
	{
		path: "/contacts/import",
		name: "ImportContacts",
		component: () => import(/* webpackChunkName: "import_contacts" */ "../views/Contacts/Import"),
		beforeEnter: isAuthenticated
	},
	{
		path: "/campaigns/create",
		name: "CreateCampaign",
		component: () => import(/* webpackChunkName: "create_campaign" */ "../views/Campaigns/Create"),
		beforeEnter: isAuthenticated
	},
	{
		path: "/campaigns/compose",
		name: "Compose",
		component: () => import(/* webpackChunkName: "compose" */ "../views/Compose"),
		beforeEnter: isAuthenticated
	},
	{
		path: "/developer",
		name: "Developer",
		component: () => import(/* webpackChunkName: "developer" */ "../views/Developer"),
		beforeEnter: isAuthenticated
	},
	{
		path: "/billing",
		name: "Billing",
		component: () => import(/* webpackChunkName: "billing" */ "../views/Billing"),
		beforeEnter: isAuthenticated
	}
]

const router = new VueRouter({
	routes: Routes,
	mode: 'history'
})

export default router
