import config from "../../../config.json"

export default {
	namespaced: true,

	state: {
		masks: []
	},

	getters: {},

	actions: {

		initialize: ({ commit }) => {

			fetch(config.api.url + "/masks", {
				method: "GET",
				headers: { "Authorization": "Bearer " + localStorage.getItem("token") }
			}).then(Response => Response.json()).then(Response => commit("set_masks", Response.data)).catch(error => console.log(error))

		}

	},

	mutations: {

		set_masks: (state, data) => {
			state.masks = data.masks
		}

	}
}
