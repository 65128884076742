import config from "../../../config.json"

export default {
	namespaced: true,

	state: {
		contacts: []
	},

	getters: {},

	actions: {

		initialize: ({ commit }) => {

			fetch(config.api.url + "/contacts", {
				method: "GET",
				headers: { "Authorization": "Bearer " + localStorage.getItem("token") }
			}).then(Response => Response.json()).then(Response => {
				commit("set_contacts", Response.data.contacts)
			})

		}

	},

	mutations: {

		set_contacts: (state, data) => {
			state.contacts = data
		}

	}
}
