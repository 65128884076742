import config from "../../../config.json"

export default {
	namespaced: true,

	state: {
		id: null,
		name: [],
		email: null,
		mobile: null,
		package: {
			name: null,
			cost: {
				flash: null,
				sms: null,
				otp: null
			}
		}
	},

	getters: {},

	actions: {

		initialize: ({ commit }) => {

			fetch(config.api.protocol + "://" + config.api.fqdn + "/v1.0/account", {
				method: "GET",
				headers: { "Authorization": "Bearer " + localStorage.getItem("token") }
			}).then(Response => Response.json()).then(Response => {
				commit("set_user", Response.data.user)
			}).catch(error => console.log(error))

		},

		login: (_, payload) => {
			return new Promise((resolve, reject) => {

				fetch(config.api.url + "/auth/login", {
					method: "POST",
					body: JSON.stringify(payload)
				}).then(Response => Response.json()).then(Response => {
					if ( Response.status === "success" ) {
						localStorage.setItem("token", Response.token)
						resolve(true)
					} else {
						reject(Response)
					}
				}).catch(error => reject(error))
			})

		},

		register: (_, payload) => {
			return new Promise((resolve, reject) => {

				fetch(config.api.url + "/auth/register", {
					method: "POST",
					body: JSON.stringify(payload)
				}).then(Response => Response.json()).then(Response => {
					if ( Response.state == "success" ) {
						localStorage.setItem("token", Response.data.token)
						resolve(true)
					} else {
						reject(Response)
					}
				})

			})
		}

	},

	mutations: {

		set_user: (state, data) => {
			state.id = data.id
			state.name = data.name
			state.email = data.email
			state.mobile = data.mobile
			state.balance = data.balance
			state.package = data.package
		}

	},
}
