import Vue from 'vue'
import Vuex from 'vuex'

// Modules ( Vuex )
import User from "./modules/user"
import Masks from "./modules/masks"
import Tokens from "./modules/tokens"
import Groups from "./modules/groups"
import History from "./modules/history"
import Contacts from "./modules/contacts"
import Campaigns from "./modules/campaigns"

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		user: User,
		masks: Masks,
		groups: Groups,
		history: History,
		contacts: Contacts,
		campaigns: Campaigns,
		tokens: Tokens
	}
})
